import Vue from 'vue'
 
/**
 * @description: 跳转的通用方法
 * @author: +c
 * @param {*} path
 * @param {*} target
 * @return {*}
 */
Vue.prototype.goToPage = function (path, target = "_self") {
    if (target == "_self") {
        if (!path.includes('?')) {
            this.$router.push({
                path
            })
        } else {
            const pathStr = path.split('?')[0]
            const queryStr = path.split('?')[1]
            const queryObj = {}
            queryStr.split('&').forEach((item) => {
                const quObj = item.split('=')
                queryObj[quObj[0]] = quObj[1]
            })
            this.$router.push({
                path: pathStr,
                query: queryObj
            })
        }
    } else if (target == "_blank") {
        if (!path.includes('http')) {
            // 新窗口
            const routeData = this.$router.resolve(
                { path }
            )
            window.open(routeData.href, '_blank')
        } else {
            window.open(path, '_blank')
        }
    }
}

//判断是不是看产品的
Vue.prototype.wzaIsProduct = function(){
    if(localStorage.getItem('wzaProduct')){
        return true
    }
    let proUrls = ['product','authorize','guidance']
    let isInUrl = false
    proUrls.forEach(item=>{
        if(window.location.href.indexOf(item)!=-1){
            isInUrl= true
        }
    })
    if(isInUrl){
        return true
    }
    
    return false
}

//判断是不是看英文站的
Vue.prototype.wzaIsEngilsh = function () {
    if (localStorage.getItem('wzaEngilsh')) {
        return true
    }
    return false
}