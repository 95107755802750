<template>
  <div>
    <div class="webnav">
      <div class="main-width" fbNav="平台导航,内容包括资讯博客，产品介绍，交互展示，评测体系，授权购买，安装指导，登录注册，个人中心">

        <img src="@/assets/img/logo.png" alt="青松无障碍网站logo" class="cup" @click="$router.push('/')">

        <a href="http://wix.easywza.com/" target="_blank" class="gjb">国际版</a>
 

        <ul class="clear">
          <!-- <li :class="{hover:$route.path.indexOf('blog')!=-1}">
            <router-link class="nav-a" to="/">资讯博客</router-link>
          </li> -->
          <li :class="{hover:$route.path=='/'||$route.path=='/product'}">
            <router-link class="nav-a" to="/product">产品介绍</router-link>
          </li>
          <li :class="{hover:$route.path=='/interactive'}">
            <router-link class="nav-a" to="/interactive">交互展示</router-link>
          </li>
          <li :class="{hover:$route.path=='/evaluating'}">
            <router-link class="nav-a" to="/evaluating">评测体系</router-link>
          </li>
          <li :class="{hover:$route.path=='/authorize'}">
            <router-link class="nav-a" to="/authorize">授权购买</router-link>
          </li>
          <li :class="{hover:$route.path=='/guidance'}">
            <router-link class="nav-a" to="/guidance">安装指导</router-link>
          </li>
          <li :class="{hover:$route.path=='/commonweal'}">
            <router-link class="nav-a" to="/commonweal">社会责任</router-link>
          </li>
          <li :class="{hover:$route.path=='/company'}">
            <router-link class="nav-a" to="/company">公司介绍</router-link>
          </li>
          <li :class="{hover:isMemberNav}">
            <router-link class="nav-a" to="/login" v-if="!islogin">登录/注册</router-link>
            <router-link class="nav-a" to="/member" v-else>个人中心</router-link>
          </li>


          
        </ul>

        
      </div>
    </div>

    <!-- <el-row :gutter="0">
      <el-col :span="12">
        <a href="./">
          <img src="@/assets/img/logo.png" width="60" height="60" alt="" srcset="" />
        </a>
      </el-col>
      <el-col :span="12">
        <el-row :gutter="0">
          <el-col class="nav-item" :span="3" :offset="10">
            <router-link class="nav-a" to="/login">登录</router-link>
          </el-col>
          <el-col class="nav-item" :span="3">
            <router-link class="nav-a" to="/register">注册</router-link>
          </el-col>
          <el-col class="nav-item" :span="3">
            <router-link class="nav-a" to="/feedback">反馈</router-link>
          </el-col>
          <el-col class="nav-item" :span="3">
            <router-link class="nav-a" to="/member" title="个人中心">
              <el-avatar v-if="avatar" class="nav-avatar" shape="circle" fit="contain" :size="30" :src="avatar" />
              <span v-else :title="nickname">个人中心</span>
            </router-link>
          </el-col>
        </el-row>
      </el-col>
    </el-row> -->

  </div>
</template>

<script>
  import { getApiToken, getNickname, getAvatar } from '@/utils/userinfo'

  export default {
    name: 'AppHeader',
    data() {
      return {
        islogin: false,
        nickname: '',
        avatar: '',

        isMemberNav: false,//是否会员的导航
      }
    },
    created() {
      this.isLogin()
    },
    watch: {
      $route: {
        handler(val, oldVal) {
          if (val.path == '/login' || val.path == '/register' || val.path.indexOf('member') != -1) {
            this.isMemberNav = true
          } else {
            this.isMemberNav = false
          }

          this.isLogin()
        },
        // 深度观察监听
        deep: true,
        immediate: true
      }
    },
    methods: {
      isLogin() {
        const token = getApiToken()
        if (token) {
          this.nickname = getNickname()
          this.avatar = getAvatar()
          this.islogin = true
        }
      }
    }
  }
</script>

<style scoped>
.gjb{
  display: inline-block;
  background: linear-gradient(90deg, #FF9732 0%, #FFC410 100%);
  color: #fff;
  padding: 0 10px;
  margin-left: 10px;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>