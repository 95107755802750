import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/page'

 
import XEUtils from 'xe-utils'
Vue.prototype.XEUtils = XEUtils
      import VXETable from 'vxe-table'
      import 'vxe-table/lib/style.css'

      Vue.use(VXETable)
 
import './static/css/css.css'
import './static/css/reset.css'



Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
