<template>
  <div id="app">
    <div v-if="webIsShow">
      <app-header></app-header>
      <div id="app-main">
        <router-view />
      </div>
      <app-footer></app-footer>

      <!-- 联系客服 -->
      <div class="qq" @click="openQQ()" v-if="wzaIsProduct()" >
        <div class="pr wecat">
          <svg t="1707666186508" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11880" width="60" height="60"><path d="M335.11 366.54c-14.48 0.12-26 11-26.06 24.6-0.07 14.73 10.95 26.13 25.23 26.09 14.43 0 26.48-11.41 26.7-25.22 0.21-12.61-12.98-25.58-25.87-25.47zM556.5 538.3a21.4 21.4 0 0 0-21.08 20.7A20.78 20.78 0 0 0 556 580c11.62 0.14 21.31-9 21.43-20.15a21 21 0 0 0-20.93-21.55zM506 417.23c14.65 0.11 26.16-11.18 26-25.53-0.1-13.56-12-25.19-25.77-25.24-13.23 0-26.06 12.16-26.39 25.08-0.32 13.72 11.74 25.58 26.16 25.69zM695.16 538.39c-10.5-0.16-20.07 9.49-20.38 20.57a20.32 20.32 0 0 0 20.4 21 20.68 20.68 0 0 0 20.91-20.69c0.12-11.16-9.46-20.7-20.93-20.88z" p-id="11881" fill="#8a8a8a"></path><path d="M907 97H121.5A24.57 24.57 0 0 0 97 121.5V907a20.06 20.06 0 0 0 20 20h790a20.06 20.06 0 0 0 20-20V117a20.06 20.06 0 0 0-20-20zM427.37 626.5c0.49 9.1-0.43 9.73-9.64 8.53-18.61-2.41-37.23-4.65-55.86-6.91a42.12 42.12 0 0 0-28.38 6.45c-17.42 11-34.93 21.8-52.43 32.65-2.13 1.32-4.42 2.37-6.64 3.55l-2.17-1.38q5.06-22.73 10.14-45.44a73 73 0 0 1 2.11-7.52c3.77-11.06-0.74-18.54-9.23-25.7-15.1-12.71-30.9-25.09-43.71-39.94-33.42-38.76-48.84-84.44-42.08-135.47 8.7-65.52 41.55-116.21 99.87-148.68 68.33-38 140-45.14 213.33-17.17 60.77 23.18 105.58 64.14 127.72 126.87 3.44 9.75 5.31 20.13 7.19 30.35 2.16 11.71 1.74 11.88-9.84 12.66-30.48 2.06-60 8.5-87.39 22.13C490 466.54 453 504.06 435.14 558.5c-7.23 22.08-9.02 44.89-7.77 68z m369.81 84.87c-6.84 7.83-14.75 14.72-22.13 22.08-12 12-13.95 25.45-6.43 40.72 2.42 4.92 3.38 10.56 5 15.87l-1.89 1.49c-7.14-3.39-14.67-6.16-21.33-10.3-20-12.47-40.51-14.67-63.53-8.2-67.94 19.08-128.49 3.21-180.83-43.14-36.38-32.23-57.57-72.65-56.92-122.21 0.73-55.49 26.73-98.34 70.59-130.4 78-57 188-52.58 260.49 11.94 64.64 57.5 76.99 153.5 16.98 222.15z" p-id="11882" fill="#8a8a8a"></path></svg>
          <div class="pa ">
            <img src="./static/img/wecat.png" alt="微信客服" class="w150">
            <p class="fs12 c999">扫描二维码，联系微信客服</p>
          </div>
        </div>
        <!-- <el-tooltip class="item" effect="dark" content="点击联系QQ客服" placement="left">
          <div class="bg-White">
            <svg t="1706405072352" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="4253" width="60" height="60">
            <path
              d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM722.5 676.4c-11.5 1.4-44.9-52.7-44.9-52.7 0 31.3-16.2 72.2-51.1 101.8 16.9 5.2 54.9 19.2 45.9 34.4-7.3 12.3-125.6 7.9-159.8 4-34.2 3.8-152.5 8.3-159.8-4-9.1-15.2 28.9-29.2 45.8-34.4-35-29.5-51.1-70.4-51.1-101.8 0 0-33.4 54.1-44.9 52.7-5.4-0.7-12.4-29.6 9.4-99.7 10.3-33 22-60.5 40.2-105.8-3.1-116.9 45.3-215 160.4-215 113.9 0 163.3 96.1 160.4 215 18.1 45.2 29.9 72.8 40.2 105.8 21.7 70.1 14.6 99.1 9.3 99.7z"
              p-id="4254" fill="#8a8a8a"></path>
          </svg>
          </div>
        </el-tooltip> -->
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader/AppHeader";
import AppFooter from "@/components/AppFooter/AppFooter";
export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      webIsShow: true,
    };
  },
  created() {
    // if (this.$route.query.pas|| localStorage.getItem('pas')) {
    //   console.log(666);
    //   this.webIsShow = true
    //   localStorage.setItem('pas',1)
    // }
  },
  methods: {
    openQQ() {
      var qqUrl = 'tencent://message/?uin=398138218&Site=www.easywza.com&Menu=yes';
      window.open(qqUrl);
    }

   

  },
};
</script>

<style>
.main-width {
  width: 1200px;
  margin: 0 auto;
}

#app-main {
  /* min-height: 860px; */
}

.qq {
  position: fixed;
  bottom: 180px;
  right: 0;
  width: 60px;
  height: 60px;
  background-color: #fff;
  cursor: pointer;
}
.wecat:hover div{
  display: block;
}
.wecat div{
  top: -70px;
  right: 70px;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.35);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.35);
  padding: 10px;
  display: none;
}
</style>
