<template>
  <div class="webfooter" fbServe="网站尾部">
    <p class="mb10"><i class="el-icon-message fs16"></i> easywza@163.com</p>
    <p>Copyright © {{!isBaiduTui?'佛山市轻松无障碍科技有限公司':'2023-2024'}} ,
      All Rights Reserved
      <a class="ml20" href="http://beian.miit.gov.cn"
        target="_blank">{{isBaiduTui?'浙ICP备2024059149号':'粤ICP备2024179487号-1'}}</a>
      <span class="ml20" v-if="!isBaiduTui"><img src="../../static/img/gaba.png" class="w15"> <a
          href="https://beian.mps.gov.cn/#/query/webSearch?code=44060602002762" rel="noreferrer"
          target="_blank">粤公网安备44060602002762</a></span>
    </p>
  </div>
</template>

<script>
  export default {
    name: 'AppFooter',
    data() {
      return {
        year: new Date().getFullYear(),
        isBaiduTui: false
      }
    },
    mounted() {
      if (window.location.href.indexOf('hzluyoke') != -1) {
        this.isBaiduTui = true
      }
    },
  }
</script>

<style scoped>

</style>