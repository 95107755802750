import Vue from 'vue'
import VueRouter from 'vue-router'
import { getApiToken } from "@/utils/userinfo"
import { Message } from 'element-ui'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/index/index')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product/index')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy/index')
  },
  {
    path: '/authorize',
    name: 'authorize',
    component: () => import('@/views/authorize/index')
  },
  {
    path: '/guidance',
    name: 'guidance',
    component: () => import('@/views/guidance/index')
  },
  {
    path: '/evaluating',
    name: 'evaluating',
    component: () => import('@/views/evaluating/index')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/mobileRegister'),
    // component: () => import('@/views/register/register'),
    meta: {
      title: '青松无障碍 - 注册'
    }
  },
 
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/mobileLogin'),
    // component: () => import('@/views/login/login'),
    meta: {
      title: '青松无障碍 - 登录'
    }
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import('@/views/member/member'),
    meta: {
      title: '青松无障碍 - 个人中心'
    }
  },
  {
    path: '/member/websiteDetail',
    name: 'Member',
    component: () => import('@/views/member/websiteDetail'),
    meta: {
      title: '青松无障碍 - 站点配置'
    }
  },
  {
    path: '/elder/index',
    name: 'elderIndex',
    component: () => import('@/views/elder/index'),
    meta: {
      title: '青松无障碍 - 长者专区'
    }
  },
  {
    path: '/elder/list',
    name: 'elderList',
    component: () => import('@/views/elder/list'),
    meta: {
      title: '青松无障碍 - 长者专区'
    }
  },
  {
    path: '/elder/detail',
    name: 'elderDetail',
    component: () => import('@/views/elder/detail'),
    meta: {
      title: '青松无障碍 - 长者专区'
    }
  },
  {
    path: '/blog/detail',
    name: 'blogDetail',
    component: () => import('@/views/blog/detail'),
    meta: {
      title: '青松无障碍 - 资讯详细'
    }
  },
  {
    path: '/kefu',
    name: 'kefu',
    component: () => import('@/views/kefu/index'),
    meta: {
      title: '青松无障碍 - 定价'
    }
  },
  {
    path: '/interactive',
    name: 'interactive',
    component: () => import('@/views/interactive/index'),
    meta: {
      title: '青松无障碍 - 交互展示'
    }
  },
  {
    path: '/commonweal',
    name: 'commonweal',
    component: () => import('@/views/commonweal/index'),
    meta: {
      title: '青松无障碍 - 社会责任'
    }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/company/index'),
    meta: {
      title: '青松无障碍 - 公司介绍'
    }
  }


  
];

const router = new VueRouter({
  // 推广的网站需要注释
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const loginList = ['Feedback', 'Member']
  const token = getApiToken()
  if (loginList.includes(to.name) && !token) {
    Message.error("请登录")
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
